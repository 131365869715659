<div mat-dialog-title class="modal-header">
  <button mat-mini-fab *ngIf="isResourceRemainderConsumption$ | async" (click)="returnLastUrl()" 
  [disabled]="!(isUrlsReturnLength | async)  ? 'disabled' : null">
    <mat-icon>keyboard_backspace</mat-icon>
  </button>
  <svg-icon [src]="getImageUrl(data.category.fpicon?.iconPath!)" [svgStyle]="{'width.px':25,'height.px':25 }">
  </svg-icon>
  <span class="fw-bold">{{data.category.categoryName}}: </span>
  <span>
    {{selectedGroup ? selectedGroup.name : ""}}
  </span>
  <button mat-button class="ms-2" *ngIf="data.category.groups?.length! > 1" (click)="isShowGroups=!isShowGroups">
    Change
  </button>

  <div class="header-checkbox">
    <mat-checkbox [(ngModel)]="isShowLabel" class="me-2"
      [matTooltip]="(isShowLabel ? 'Hide' : 'Show') + ' labels on report'" (change)="changeShowLabel()">H
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="isToolbarActive"
      [matTooltip]="(isToolbarActive ? 'Hide' : 'Show') + ' toolbar for export & print'" (change)="changeToolbar()">T
    </mat-checkbox>
  </div>
</div>

<div mat-dialog-content>
  <div class="modal-body">
    <div *ngIf="isShowGroups && data.category.groups?.length! > 1"
      class="left-body btn-group-vertical scrollbar-primary">
      <h6>Resources</h6>
      <div *ngFor="let group of data.category.groups" class="pt-1 w-100">
        <button class="btn w-100" (click)="changeGroup(group)"
          [ngClass]="{'btn-primary': selectedGroup == group, 'btn-outline-primary': selectedGroup != group}">
          {{group.name}}
        </button>
      </div>
    </div>

    <div class="right-body scrollbar-primary">
      <div class="spinner">
        <mat-spinner class="spinner-body" [ngStyle]="{ display: !(isLoading$ | async) ? 'none' : 'block' }">
        </mat-spinner>
      </div>
      <iframe *ngIf="!isUrlSafeLoad; else notSafe" width="115%" height="550px" frameBorder="0"
        [src]="current_url"></iframe>
      <ng-template #notSafe style="width:100%; height:550px">
        <mat-spinner></mat-spinner>
      </ng-template>
    </div>
  </div>

</div>

<div mat-dialog-actions class="d-block">
  <div class="modal-footer">
    <div *ngIf="isShowDatepicker" class="date-and-period">
      <mat-button-toggle-group [(ngModel)]="selectedPeriod" (change)="changePeriod($event.value)">
        <mat-button-toggle *ngFor="let period of aPeriodList" [value]="period"
          [matTooltip]="'Click to select ' + period +  ' timescale'">{{period}}</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field style="margin-bottom: -1.25em">
        <mat-label>Start date</mat-label>
        <input matInput [(ngModel)]="startDate" [matDatepicker]="startDatePicker" (dateChange)="changeStartDate()">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field style="margin-bottom: -1.25em">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="endDate" [matDatepicker]="endDatePicker" (dateChange)="changeEndDate()">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <mat-button-toggle-group [(ngModel)]="chartType" (change)="changeChartType($event.value)">
      <mat-button-toggle *ngFor="let chart of chartTypeList" [value]="chart" [matTooltip]="chart.tooltip">
        <mat-icon>{{chart.iconName}}</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div class="ml-4">
      <div *ngIf="isShowCompare">
        <mat-checkbox [(ngModel)]="isCompare" (change)="changeCompare()">Compare</mat-checkbox>
      </div>
    </div>

  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">
    Close
  </button>
</mat-dialog-actions>