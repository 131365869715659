export interface IUser {
  nameIdentifier: string;
  name: string;
  surname: string;
  email: string;
  role: string;
}

export interface IExtractedUserFromJWT {
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
  exp: number;
  iss: string;
  aud: string;
}

export class User implements IUser {
  nameIdentifier: string;
  name: string;
  surname: string;
  email: string;
  role: string;

  constructor(tokenUser: IExtractedUserFromJWT) {
    this.nameIdentifier = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
    this.name = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    this.surname = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"];
    this.email = tokenUser["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
    this.role = tokenUser["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  }
}
