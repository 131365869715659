import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError, } from "rxjs";
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { catchError, filter, map } from "rxjs/operators";
import { environment } from '@environment/environment';
import { ErrorHandlerService } from '@helpers/error-handler.service';
import { UserStateService } from '@states/user-state.service';

@Injectable({
    providedIn: "root"
})
export class GoogleMapsService {

    constructor(private httpClient: HttpClient, private errorHandler: ErrorHandlerService, private userStateService: UserStateService) {
    }

    googleMapAPIKey$ = this.userStateService.isLoggedIn$.pipe(
        filter(Boolean),
        switchMap(() => this.getGoogleMapsAPIKey$()),
        tap(apiKey => {
            if (!this.checkIfGoogleMapsAPIKeyIsSet()) {
                let googleMapsScript = document.createElement("script");
                googleMapsScript.type = "text/javascript";
                googleMapsScript.async = true;
                googleMapsScript.defer = true;
                googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=visualization`;
                document.head.appendChild(googleMapsScript);
            }
        }),
        shareReplay(1)
    )

    isGoogleAPIReady$ = this.googleMapAPIKey$.pipe(
        switchMap(key => this.isGoogleMapApiReady$(key)),
        shareReplay(1)
    )

    private isGoogleMapApiReady$(googleMapAPIKey: string): Observable<boolean> {
        return this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=' + `${googleMapAPIKey}`, 'callback')
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    private getGoogleMapsAPIKey$() {
        return this.httpClient.get(`${environment.apiUrl}usermenu/googleMapsAPIKey`,
            { responseType: 'text' })
            .pipe(
                catchError(
                    (error: HttpErrorResponse) => {
                        this.errorHandler.handleError(error);

                        return throwError(error);
                    }
                )
            );
    }

    private checkIfGoogleMapsAPIKeyIsSet() {
        return document.querySelector("script[src*='maps.googleapis.com']")
    }
}
