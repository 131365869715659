import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from '@views/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './angular-material/material/material.module';
import { JwtModule } from "@auth0/angular-jwt";
import { AuthGuard } from '@guards/auth.guard';
import { environment } from '@environment/environment';
import { RequestResetPasswordComponent } from '@views/request-reset-password/request-reset-password.component';
import { NavbarComponent } from '@shared/navbar/navbar.component';
import { HomeComponent } from '@views/home/home.component';
import { UnauthorizedInterceptor } from "@interceptors/unauthorized.interceptor";
import { ConfirmResetPasswordComponent } from '@views/confirm-reset-password/confirm-reset-password.component';
import { PageNotFoundComponent } from '@views/page-not-found/page-not-found.component';
import { AcceptTermsComponent } from '@views/accept-terms/accept-terms.component';
import { SafePipe } from '@pipes/safe.pipe';
import { ModalAggregatedReportComponent } from './modal/modal-aggregated-report/modal-aggregated-report.component';
import { AngularSvgIconModule } from "angular-svg-icon";
import { ModalDashboardComponent } from './modal/modal-dashboard/modal-dashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ModalMapComponent } from './modal/modal-map/modal-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ModalReportsComponent } from './modal/modal-reports/modal-reports.component';
import {
    ChartsLineChartComponent
} from './modal/modal-dashboard/_components/charts-line-chart/charts-line-chart.component';
import { HiddenTestNotificationComponent } from '@views/hidden-test-notification/hidden-test-notification.component';
import { DragDropModule } from "@angular/cdk/drag-drop";

function tokenGetter() {
    return localStorage.getItem("jwt");
}

@NgModule({
    declarations: [
        // components
        AppComponent,
        LoginComponent,
        RequestResetPasswordComponent,
        NavbarComponent,
        HomeComponent,
        ConfirmResetPasswordComponent,
        PageNotFoundComponent,
        AcceptTermsComponent,
        ModalAggregatedReportComponent,
        ModalDashboardComponent,
        ModalMapComponent,
        ModalReportsComponent,
        ChartsLineChartComponent,
        HiddenTestNotificationComponent,

        // pipes
        SafePipe,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        AngularSvgIconModule.forRoot(),
        GoogleMapsModule,
        NgxChartsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: [environment.allowedDomain],
            }
        }),
        DragDropModule
    ],
    providers: [AuthGuard, { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
