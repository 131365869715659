import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "@helpers/error-handler.service";
import {environment} from "@environment/environment";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";

@Injectable()
export class HiddenTestNotificationService {

  constructor(private httpClient: HttpClient, private errorHandler: ErrorHandlerService) {
  }

  badReq() {
    return this.httpClient.get<void>(`${environment.apiUrl}errornotification/badreq`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  notFoundReq() {
    return this.httpClient.get<void>(`${environment.apiUrl}errornotification/notfoundreq`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  unauthorizedReq() {
    return this.httpClient.get<void>(`${environment.apiUrl}errornotification/unauthorizedreq`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  forbiddenReq() {
    return this.httpClient.get<void>(`${environment.apiUrl}errornotification/forbiddenreq`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  internalErrorReq() {
    return this.httpClient.get<void>(`${environment.apiUrl}errornotification/internalerrorreq`).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }
}
