import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@api/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {take} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService]
})
export class ConfirmResetPasswordComponent {

  resetPasswordFG: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    token: new FormControl('', [Validators.required])
  });

  public isConfirmPassword = false;

  get currentYear(): number {
    return new Date().getFullYear();
  }

  constructor(private authService: AuthService, private snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) {
    console.log(this.route.snapshot.queryParams.token);

    this.resetPasswordFG.patchValue({'token': this.route.snapshot.queryParams.token});
  }

  onConfirmResetPasswordSubmit() {
    if (this.resetPasswordFG.value.password !== this.resetPasswordFG.value.confirmPassword) {
      this.snackBar.open('Passwords do not match', '', {duration: 4000, panelClass: 'custom-snackbar-warning'});
      return;
    }

    if (this.resetPasswordFG.valid) {
      this.authService.confirmResetPassword(this.resetPasswordFG.value).pipe(take(1)).subscribe();
      this.router.navigate(['/']);
      this.isConfirmPassword = true;
    }
  }
}
