<h2 mat-dialog-title>
    <svg-icon class="d-inline-block me-2" src="assets/images/svg/nav-bar/Dashboard Report.svg"
        [svgStyle]="{ 'width.px':20,'height.px': 20,'fill':'#000'}">
    </svg-icon>
    Dashboard Report
</h2>
<mat-dialog-content class="mat-typography">
    <div class="modal-body">
        <div *ngFor="let chartData of chartDataArr">
            <div class="chart-body">
                <h3>{{chartData.group.name}}</h3>
                <app-charts-line-chart  [chart]="chartData.chart | async"></app-charts-line-chart>
                <div class="buttons">
                    <button mat-raised-button *ngFor="let button of buttonList"
                        (click)="lodDataByPeriod(button.period, chartData)">
                        {{button.name}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="onNoClick()">
        Close
    </button>
</mat-dialog-actions>