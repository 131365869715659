import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {HiddenTestNotificationService} from "@api/hidden-test-notification.service";

@Component({
  selector: 'app-hidden-test-notification',
  templateUrl: './hidden-test-notification.component.html',
  styleUrls: ['./hidden-test-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HiddenTestNotificationService]
})
export class HiddenTestNotificationComponent implements OnInit {

  constructor(private errorNotificationService: HiddenTestNotificationService) {
  }

  ngOnInit(): void {
  }

  async onBadReqClick() {
    await this.errorNotificationService.badReq().toPromise();
  }

  async onNotFoundReqClick() {
    await this.errorNotificationService.notFoundReq().toPromise();
  }

  async onUnauthorizedReqClick() {
    await this.errorNotificationService.unauthorizedReq().toPromise();
  }

  async onForbiddenReqClick() {
    await this.errorNotificationService.forbiddenReq().toPromise();
  }

  async onInternalErrorReqClick() {
    await this.errorNotificationService.internalErrorReq().toPromise();
  }

}
