<div class="container">
  <div class="content">
    <div class="header">
      <h2 class="text-info">TiCCBox Carbon PORTAL</h2>
    </div>
    <div class="body">

      <div class="container">
        <div id="login-row" class="row justify-content-center align-items-center">
          <div id="login-column" class="col-md-8">
            <div id="login-box" class="col-md-12">
              <h3 class="text-info text-center">Reset password</h3>
              <div class="spinner">
                <mat-spinner class="spinner-body" [ngStyle]="{ display: !isConfirmPassword ? 'none' : 'block' }"></mat-spinner>
              </div>
              <form [formGroup]="resetPasswordFG"  [ngClass]="{ blur: isConfirmPassword }" (ngSubmit)="onConfirmResetPasswordSubmit()">
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input [attr.disabled]="isConfirmPassword? true : null" type="password" class="form-control" id="password" formControlName="password">
                </div>
                <div class="mb-3">
                  <label for="confirm-password" class="form-label">Confirm password</label>
                  <input [attr.disabled]="isConfirmPassword? true : null" type="password" class="form-control" id="confirm-password" formControlName="confirmPassword">
                </div>
                <div class="d-flex justify-content-between">
                  <button type="submit" [disabled]="isConfirmPassword ? true : null" class="btn btn-info btn-md text-white">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="d-flex justify-content-around">
      <div> Email: <a href="mailto:info@ticcbox.com">info@ticcbox.com</a>
      </div>
      <div>
        <a href="assets/documents/DCS_Energy_Savings_Ltd_End_User_License_Agreement.pdf" target="_blank"> Terms of
          Use and Privacy Policy </a>
      </div>
      <div> © 2008-{{currentYear}} by <a href="https://ticcbox.com" target="_blank">ticcbox.com</a>
      </div>
    </div>
  </div>
</div>
