import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";

import * as moment from 'moment';
import {FormControl} from "@angular/forms";
import {map, takeUntil, tap} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

export  const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-modal-aggregated-report',
  templateUrl: './modal-aggregated-report.component.html',
  styleUrls: ['./modal-aggregated-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT},
  ],
})
export class ModalAggregatedReportComponent implements OnInit, OnDestroy {

  startDate = new FormControl(moment().startOf('day'));
  endDate = moment().startOf('day').add('months', 1)

  isToolBarActive$ = new BehaviorSubject<boolean>(false);

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  url: string | null = null;

  constructor(private dialogRef: MatDialogRef<ModalAggregatedReportComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { url: Observable<string>, portalId: number }) {
  }

  ngOnInit() {

    this.data.url.pipe(
      takeUntil(this.destroyed$),
      map(url => {
        const params = new HttpParams()
          .set('aPeriod', 'm')
          .set('aDateLeft', this.startDate.value.format('YYYY-MM-DD HH:mm:ss'))
          .set('aDateRight', this.endDate.format('YYYY-MM-DD HH:mm:ss'))
          .set('aPortalID', String(this.data.portalId))
          .set('rc:Toolbar', this.isToolBarActive$.getValue());

        return url + params.toString();
      })
    ).subscribe(url => {
      this.url = url;
    })

    this.startDate.valueChanges.pipe(
      tap((dateTime) => {
          this.endDate = moment(dateTime).add('months', 1);

          const params = new HttpParams()
            .set('aPeriod', 'm')
            .set('aPortalID', this.data.portalId)
            .set('aDateLeft', dateTime.format('YYYY-MM-DD HH:mm:ss'))
            .set('aDateRight', this.endDate.format('YYYY-MM-DD HH:mm:ss'))
            .set('rc:Toolbar', this.isToolBarActive$.getValue());

          const url = new URL(this.url as string);
          url.search = '?%2fDCS.Reports%2frptAggregatedReport&' + params.toString();
          this.url = url.toString();
        },
        takeUntil(this.destroyed$))
    ).subscribe();


    this.isToolBarActive$.pipe(takeUntil(this.destroyed$), tap(
      (isToolBarActive) => {
        if (this.url) {
          const params = new HttpParams()
            .set('aPeriod', 'm')
            .set('aPortalID', this.data.portalId)
            .set('aDateLeft', this.startDate.value.format('YYYY-MM-DD HH:mm:ss'))
            .set('aDateRight', this.endDate.format('YYYY-MM-DD HH:mm:ss'))
            .set('rc:Toolbar', isToolBarActive);


          const url = new URL(this.url as string);
          url.search = '?%2fDCS.Reports%2frptAggregatedReport&' + params.toString();
          this.url = url.toString();
        }
      }
    )).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
