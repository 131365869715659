import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IExtractedUserFromJWT } from '@models/user.model';
import { UserStateService } from '@states/user-state.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private jwtHelper: JwtHelperService, private userStateService: UserStateService) { }


  canActivate() {
    const token = localStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      this.userStateService.currentUserSource.next(this.jwtHelper.decodeToken(token) as IExtractedUserFromJWT);

      return true;
    }
    this.router.navigate(["login"]);
    return false;
  }

}
