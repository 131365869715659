import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PortalVM} from "@models/backend-generated-models/models";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PortalStateService {
  selectedPortalSource = new BehaviorSubject<PortalVM | null>(null);
  selectedPortal$ = this.selectedPortalSource.pipe(tap(
    (portal: PortalVM | null) => {
      if (portal) {
        localStorage.setItem('portalId', portal.portalId!.toString());
      }
    }
  ));

  constructor() {
  }
}
