import { Component } from '@angular/core';
import { UserStateService } from '@states/user-state.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    loggedIn$ = this.userStateService.isLoggedIn$;

    constructor(private userStateService: UserStateService) {
    }
}
