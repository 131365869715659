import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@api/auth.service';
import { Subject } from 'rxjs';
import { take, tap, finalize } from 'rxjs/operators';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AuthService]
})
export class LoginComponent implements OnDestroy {

    isLoginInProces$ = new Subject<boolean>();

    loginFG: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });
  
    get currentYear(): number {
      return new Date().getFullYear();
    }

    constructor(private authService: AuthService) {
    }

    onLoginSubmit() {
        if (this.loginFG.valid) {
            this.isLoginInProces$.next(true);
            this.authService.login(this.loginFG.value)
                .pipe(
                    take(1),
                    finalize(
                        () => this.isLoginInProces$.next(false)
                    )
                ).subscribe();
        }
    }

    ngOnDestroy(): void {
        this.isLoginInProces$.complete();
    }
}