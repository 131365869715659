<ng-container *ngIf="(loggedIn$ | async); else notLoggedIn">
    <div class="navbar-container">
        <app-navbar></app-navbar>
    </div>

    <div class="router-outlet-container">
        <router-outlet></router-outlet>
    </div>
</ng-container>
<ng-template #notLoggedIn>
    <router-outlet></router-outlet>
</ng-template>