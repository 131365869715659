<h2 mat-dialog-title class="d-flex justify-content-start align-items-center">
    <svg-icon class="d-inline-block me-2" src="assets/images/svg/nav-bar/Map.svg"
        [svgStyle]="{ 'width.px':20,'height.px': 20,'fill':'#000'}">
    </svg-icon>
    Map
</h2>

<div mat-dialog-content>

    <mat-form-field appearance="fill" class="w-100 mb-3">
        <mat-label>Site</mat-label>
        <mat-select [(value)]="selectedItem">
            <mat-option *ngFor="let item of (sites$ | async)" [value]="item" (click)="selectSite(item)">
                {{item.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="hasNoLocation;else hasLocation" class="not-location-message">
        <h3>No location found</h3>
        <google-map [center]="markerPosition">
            <map-marker [position]="markerPosition" [options]="markerOptions">
            </map-marker>
        </google-map>
    </div>

    <ng-template #hasLocation>
        <google-map *ngIf="googleMapApiReady$ | async" [center]="markerPosition">
            <map-marker [position]="markerPosition" [options]="markerOptions">
            </map-marker>
        </google-map>
    </ng-template>


</div>

<mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Close</button>
</mat-dialog-actions>