<div class="wrapper">
  <ng-container *ngFor="let item of mainItems">
    <div #mainItem class="main-item" [style.color]="item.hoverColorHex">
      <div class="item" (click)="showFpItems(mainItem)">
        <div class="item-title">
          <h1>{{item.name}}</h1>
        </div>
        <div class="item-image">
          <img src="{{item.imageUrl}}" alt="image">
        </div>
      </div>
      <div cdkDropList
           cdkDropListOrientation="horizontal"
           (cdkDropListDropped)="drop($event, item.id)"
           class="categories hide">
        <div
          cdkDrag
          cdkDragBoundary=".categories"
          class="category"
          #category
          *ngFor="let fpResourceCategory of (item.fpResourceCategories | async)"
          [cdkDragData]="fpResourceCategory"
          [cdkDragDisabled]="!fpResourceCategory?.fpcategoryId"
          (click)="openReportModal(fpResourceCategory, category)">
          <svg-icon
            [src]="getFpItemIcon(fpResourceCategory?.fpicon?.iconPath)"
            [svgStyle]="{ 'width.px':75,'height.px': 70,'fill': item.hoverColorHex }"></svg-icon>
          <span>
            {{fpResourceCategory?.categoryName!}}
          </span>

          <div class="handle" cdkDragHandle *ngIf="fpResourceCategory?.fpcategoryId">
            <svg width="24px" fill="black" viewBox="0 0 24 24">
              <path
                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
