import { Component, OnInit, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserMenuService } from '@api/user-menu.service';
import { LineChartViewMode } from '@models/backend-generated-models/models';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-dashboard',
  templateUrl: './modal-dashboard.component.html',
  styleUrls: ['./modal-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDashboardComponent implements OnInit {

  chartDataArr: any[] = [];

  buttonList: any[] = [
    { period: '1d', name: 'Live' },
    { period: '7d', name: 'W' },
    { period: '1m', name: 'M' },
    { period: '90d', name: '3M' },
    { period: '180d', name: '6M' },
    { period: '12m', name: '1Y' }];

  constructor(private userMenuService: UserMenuService,
    private dialogRef: MatDialogRef<ModalDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: { logicalGroupList: [{ name: string, id: number }], siteId: number }) {

  }
  dataChart$: Observable<LineChartViewMode[] | undefined> = this.userMenuService.getRptResourceConsumption$(2447, 10365, "12m", "2021-02-02 00:00:00", "2022-02-02 00:00:00")
    .pipe(map((chart => { return chart.data })));

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    let dateLeft = new Date();
    dateLeft.setHours(0, 0, 0);
    let dateRight = new Date(dateLeft);
    this.datas.logicalGroupList.forEach(forDashboardGroup => {
      this.chartDataArr.push({
        chart: this.userMenuService.getRptResourceConsumption$(this.datas.siteId, forDashboardGroup.id as number, "1d", this.dateToString(dateLeft), this.dateToString(dateRight))
          .pipe(
            map((chart => {
              let length;
              chart.data?.forEach(x => {
                length = x.series?.length
              })

              if (length == 0) {
                return null;
              } else {
                return chart.data;
              }
            }))),
        group: forDashboardGroup,
        period: "1d"
      })
    });
  }

  lodDataByPeriod(period: any, chartData: any) {
    let dateLeft = new Date();
    dateLeft.setHours(0, 0, 0);
    let dateRight = new Date(dateLeft);

    if (period == "7d") {
      dateLeft.setDate(dateLeft.getDate() - 7);
    }
    else if (period == "1m") {
      dateLeft.setMonth(dateLeft.getMonth() - 1);
    }
    else if (period == "90d") {
      dateLeft.setMonth(dateLeft.getMonth() - 3);
    }
    else if (period == "180d") {
      dateLeft.setMonth(dateLeft.getMonth() - 6);
    }
    else if (period == "12m") {
      dateLeft.setMonth(dateLeft.getMonth() - 12);
    }
    else if (period == "1d") {
      dateLeft.setDate(dateLeft.getDate() - 1);
    }

    chartData.chart = this.userMenuService.getRptResourceConsumption$(this.datas.siteId, chartData.group.id, period, this.dateToString(dateLeft), this.dateToString(dateRight))
      .pipe(map((chart => {
        let length;
        chart.data?.forEach(x => {
          length = x.series?.length
        })

        if (length == 0) {
          return null;
        } else {
          return chart.data;
        }
      })));

  }

  onNoClick() {
    this.dialogRef.close();
  }

  dateToString(date: Date): string {
    if (date == undefined)
      return "YYYY-MM-DD HH:mm:ss";

    return moment(date).format('YYYY-MM-DDTHH:mm:ss')
  }
}
