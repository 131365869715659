import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '@environment/environment'
import {catchError} from 'rxjs/operators';
import {ErrorHandlerService} from '@helpers/error-handler.service';
import {throwError} from 'rxjs';
import {FPCategoryVM, SiteWithCoordinatesVM, UpdateFPCategoryOrderRequest} from "@models/backend-generated-models/models";

@Injectable({providedIn: 'root'})
export class SiteService {
  constructor(private httpClient: HttpClient, private errorHandler: ErrorHandlerService) {
  }

  deepLoadSite$(siteId: number) {
    return this.httpClient.get<FPCategoryVM[]>(`${environment.apiUrl}site/deepLoadSite`, {
      params: new HttpParams().set("siteId", siteId)
    }).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        }
      )
    );
  }

  getSitesWithCoordinates$(portalId: number | undefined) {
    if (!portalId) return throwError('PortalId is required');

    return this.httpClient.get<SiteWithCoordinatesVM[]>(`${environment.apiUrl}site/${portalId}`).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        }
      )
    );
  }


  updateFPCategoryOrderPerSite(siteId: number, fpCategoryId: number, locationId: string, order: number) {
    return this.httpClient.put<FPCategoryVM>(`${environment.apiUrl}site/updateFPCategoryOrderPerSite`, {
      siteId,
      fpCategoryId,
      locationId,
      order
    } as UpdateFPCategoryOrderRequest).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return throwError(error);
        }
      )
    )
  }
}
