<h2 mat-dialog-title>
  <img width="20px"
       height="20px"
       src="assets/images/svg/nav-bar/Aggregate.svg"
       alt="aggregate icon"/>
  Aggregated Report
</h2>
<mat-dialog-content class="mat-typography">

  <div class="frame-container mb-3" *ngIf="url; else noData">
    <iframe
      [src]="url | safe"
      allowfullscreen></iframe>
  </div>
  <ng-template class="mb-3" #noData>
    <div class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

  <div class="control-container row row-cols-lg-auto g-3 align-items-center">
    <mat-form-field appearance="fill">
      <mat-label>Month and Year</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="startDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="multi-year" panelClass="month-picker"></mat-datepicker>
    </mat-form-field>

    <mat-checkbox (change)="isToolBarActive$.next($event.checked)" class="aggregated-margin">Show Toolbar for
      Export
      & Print
    </mat-checkbox>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">
    Close
  </button>
</mat-dialog-actions>
