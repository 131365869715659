import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleMapsService } from "@api/google-maps.service";
import { SiteService } from '@api/site.service';
import { SiteWithCoordinatesVM } from '@models/backend-generated-models/models';
import { Observable } from 'rxjs';
import { take, tap } from "rxjs/operators";

@Component({
    selector: 'app-modal-map',
    templateUrl: './modal-map.component.html',
    styleUrls: ['./modal-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalMapComponent {

    selectedItem: any;

    hasNoLocation: boolean = false;

    googleMapApiReady$: Observable<boolean> = this.googleMapsService.isGoogleAPIReady$;

    markerOptions: google.maps.MarkerOptions = { draggable: false };

    markerPosition: google.maps.LatLngLiteral = { lat: 0, lng: 0 };

    sites$: Observable<SiteWithCoordinatesVM[]> = this.siteService.getSitesWithCoordinates$(this.data.portalId)
        .pipe(
            take(1),
            tap((sites) => {
                const firstSite = sites[0];
                this.selectedItem = firstSite;
                if (

                    Math.round(firstSite.latitude!) === 0 &&
                    Math.round(firstSite.longitude!) === 0
                ) {
                    this.hasNoLocation = true;
                    return;
                }
                this.markerPosition = { lat: firstSite.latitude!, lng: firstSite.longitude! }
            })
        );

    constructor(private dialogRef: MatDialogRef<ModalMapComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { portalId: number },
        private siteService: SiteService,
        private googleMapsService: GoogleMapsService) {
    }

    selectSite(item: SiteWithCoordinatesVM) {

        // if both the latitude and longitude are 0, then the site has no location
        if (

            Math.round(item.latitude!) === 0 &&
            Math.round(item.longitude!) === 0
        ) {
            this.hasNoLocation = true;
            return;
        }

        this.hasNoLocation = false;

        this.markerPosition = { lat: item.latitude!, lng: item.longitude! }
    }

    onNoClick() {
        this.dialogRef.close();
    }
}

