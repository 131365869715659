import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PortalService } from '@api/portal.service';
import { UserMenuService } from '@api/user-menu.service';
import { PortalStateService } from '@states/portal-state.service';
import { SiteStateService } from '@states/site-state.service';
import { UserStateService } from '@states/user-state.service';
import { of } from 'rxjs';
import { filter, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ModalAggregatedReportComponent } from 'src/app/modal/modal-aggregated-report/modal-aggregated-report.component';
import { PortalVM, SiteVM } from "@models/backend-generated-models/models";
import { ModalMapComponent } from 'src/app/modal/modal-map/modal-map.component';
import { ModalDashboardComponent } from 'src/app/modal/modal-dashboard/modal-dashboard.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
    portals$ = this.portalService.getPortals$()
        .pipe(
            take(1),
            tap(
                (portals: PortalVM[]) => {

                    const portalId = localStorage.getItem('portalId');

                    if (!portalId) {
                        this.setDefaultPortal(portals[0]);
                        return;
                    }

                    const portal = portals.find(p => p.portalId === +portalId);

                    if (!portal) {
                        this.setDefaultPortal(portals[0]);
                        return;
                    }

                    this.portalStateService.selectedPortalSource.next(portal);
                }
            )
        );

    selectedPortal$ = this.portalStateService.selectedPortal$.pipe(filter(q => !!q));

    sites$ = this.selectedPortal$
        .pipe(
            switchMap(p =>
                this.portalService.getSitesForPortal$(p!.portalId)
            ), tap(sites => {

                const siteId = localStorage.getItem('siteId');

                if (!siteId) {
                    this.setDefaultSite(sites[0]);
                    return;
                }

                const site = sites.find(s => s.siteId === +siteId);

                if (!site) {
                    this.setDefaultSite(sites[0]);
                    return;
                }

                this.siteStateService.selectedSiteSource.next(site);
            })
        )

    selectedSite$ = this.siteStateService.selectedSite$;

    logos$ = this.selectedSite$
        .pipe(
            switchMap(
                site => {
                    if (site) {
                        return this.portalService.getLogos$(site.siteId);
                    } else {
                        return of(null);
                    }
                }
            )
        );

    userManuelUrl$ = this.userMenuService.getUserManualURL$();
    aggregatedReportUrl$ = this.userMenuService.getAggregatedReportURL$().pipe(shareReplay(1));

    logicalGroupList = [
        { name: 'Total Electricity (for Dashboard purposes only)', id: 9151 },
        { name: 'Total Gas (for Dashboard purposes only)', id: 10365 },
        { name: 'Total Water (for Dashboard purposes only)', id: 10518 },
    ]

    constructor(
        private portalService: PortalService,
        private router: Router,
        public dialog: MatDialog,
        private userStateService: UserStateService,
        private portalStateService: PortalStateService,
        private siteStateService: SiteStateService,
        private userMenuService: UserMenuService,
    ) {
    }

    onSelectedPortalChange(portal: PortalVM) {
        this.portalStateService.selectedPortalSource.next(portal);
    }

    onSelectedSiteChange(site: SiteVM) {
        this.siteStateService.selectedSiteSource.next(site);
    }

    openDashboardReport() {
        this.dialog.open(ModalDashboardComponent, {
            data: {
                logicalGroupList: this.logicalGroupList,
                siteId: this.siteStateService.selectedSiteSource.getValue()?.siteId
            }
        });
    }

    openAggregatedReport() {
        this.dialog.open(ModalAggregatedReportComponent, {
            data: {
                url: this.aggregatedReportUrl$,
                portalId: this.portalStateService.selectedPortalSource.getValue()?.portalId
            },
        });
    }

    openMap() {
        this.dialog.open(ModalMapComponent, {
            data: {
                portalId: this.portalStateService.selectedPortalSource.getValue()?.portalId,
            }
        })
    }

    private setDefaultPortal(portal: PortalVM) {
        this.portalStateService.selectedPortalSource.next(portal);

        localStorage.setItem('portalId', portal.portalId!.toString());
    }

    private setDefaultSite(site: SiteVM) {
        this.siteStateService.selectedSiteSource.next(site);

        localStorage.setItem('siteId', site.siteId!.toString());
    }

    onLogout() {
        localStorage.clear();
        this.userStateService.currentUserSource.next(null);
        this.router.navigate(['login']);
    }

}
