import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '@environment/environment';
import {ErrorHandlerService} from '@helpers/error-handler.service';
import {
  AcceptTermsRequestModel,
  AuthenticatedResponse,
  ConfirmResetPasswordRequestModel,
  LoginRequestModel,
  RequestResetPasswordRequestModel
} from '@models/backend-generated-models/models';
import {throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class AuthService {

  constructor(private router: Router, private http: HttpClient, private errorHandler: ErrorHandlerService, private snackBar: MatSnackBar) {
  }

  login(body: LoginRequestModel) {
    return this.http.post<AuthenticatedResponse>(`${environment.apiUrl}auth/login`, body).pipe(
      tap(
        (response) => {
          localStorage.setItem("jwt", response.token as string);
          this.router.navigate(['/']);
        }
      ),
      catchError((error: HttpErrorResponse) => {

        if (error.error.message === 'User should reset the password') {
          this.router.navigate(['/request-reset-password'], {
            queryParams: {
              email: body.email
            }
          });

          return throwError(error);
        }

        if (error.error.message === 'User should accept the terms') {
          this.router.navigate(['/accept-terms']);

          return throwError(error);
        }

        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  confirmResetPassword(body: ConfirmResetPasswordRequestModel) {
    return this.http.post<void>(`${environment.apiUrl}auth/confirmResetPassword`, body).pipe(
      tap(() => {
        const snackBarRef = this.snackBar.open('Your password has been reset', 'Ok', {duration: 4000});

        snackBarRef.onAction().subscribe(() => {
          this.router.navigate(['/login']);
        })
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  requestResetPassword(body: RequestResetPasswordRequestModel) {
    return this.http.post<void>(`${environment.apiUrl}auth/requestResetPassword`, body).pipe(
      tap(
        () => {
          this.snackBar.open('Check your email for a link to reset your password', 'Ok', {duration: 4000});
        }
      ),
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }

  acceptTerms(body: AcceptTermsRequestModel, token: string) {
    return this.http.post<void>(`${environment.apiUrl}auth/acceptTerms`, body).pipe(
      tap(
        () => {
          this.router.navigate(['/confirm-reset-password'], {queryParams: {token}});
        }
      ),
      catchError((error: HttpErrorResponse) => {

        if (error.error.message === 'User has not accepted the terms') {
          this.router.navigate(['/accept-terms']);
          return throwError(error);
        }

        this.errorHandler.handleError(error);

        return throwError(error);
      })
    );
  }
}
