<div class="container">
  <div class="content">
    <div class="header">
      <h2 class="text-info">TiCCBox Carbon PORTAL</h2>
    </div>
    <div class="body">
      <p class="text-info fw-bold ms-2">Terms of use and Privacy policy</p>
      <p class="ms-2">Please review and accept to the Terms of Use and Privacy Policy.</p>
      <div class="terms mb-3">


        <br>TERMS OF USE UPDATED: JUNE 16th, 2010<br>
        <br>PRIVACY POLICY UPDATED: JUNE 16th, 2010<br>

        <br><br><span class="SubHead">TERMS OF USE</span><br><br>

        These terms of use <strong>("Terms of Use")</strong> apply to the TiCCBox: Web Portal (“Portal”).
        The Portal is owned by TiCCBox: Carbon and any of its successors and assigns
        <strong>("TiCCBox")</strong>.
        The level of access to features and capabilities may vary depending on the version or level of access
        provided with these Terms of Use.
        As used in the Terms of Use and Privacy Policy, the terms <strong>
          "you", "your", "yourself" or "user"
        </strong> all refer to the person or entity using the
        Portal in any way.

        <br><br><span class="SubHead">1. Permitted Uses and Restrictions on Use</span><br><br>
        Subject to these terms and conditions of the Terms of Use and the Privacy Policy, TiCCBox will provide Portal
        Access that allows you to use the Portal on the World Wide Web. You have only a limited, non-exclusive,
        non-transferable ability to use the Portal for your internal business operations. Except as expressly stated
        herein, these Terms of Use are not a license agreement and your use of the Service does not grant you the
        status of a licensee. You may not use the Portal until you have read and accepted all of the Terms of Use
        and the Privacy Policy by checking the "I accept" checkbox. By doing so, you further agree to the Acceptable
        Use Policy which is part of the Terms of Use and is stated at the end of the Terms of Use. In the event you
        do not agree with any provision of the Terms of Use or Privacy Policy, do not access the Portal. In order to
        use the Portal, you must at your own expense obtain access to the World Wide Web, either directly or through
        devices that access web-based content. In addition, you must provide all equipment necessary to make such
        connection to the World Wide Web, including a computer and modem or other access device. The Portal supports
        users whose principle place of business is world wide.

        <br><br><span class="SubHead">2. Registration and Content</span><br><br>
        In consideration of your use of the Portal, you agree to: (a) provide true, accurate, current and complete
        information about yourself as prompted by the TiCCBox and (b) maintain and promptly update the information to
        keep it true, accurate, current and complete. If you provide any information that TiCCBox has reasonable grounds
        to suspect is untrue, inaccurate, not current or incomplete, TiCCBox may suspend or terminate your account and
        refuse any and all current or future use of the Portal (or any portion thereof). By using the Portal and
        submitting data, files, software, text (including but not limited to names of files, databases and
        directories), software, music, sound, photographs, video or any other material (collectively, the
        <strong>"Content"</strong>) TiCCBox in connection with this Portal, you represent and warrant that: (i) you are
        the owner of such Content or are authorized by the owner, and (ii) the use of Content by TiCCBox will not
        infringe or misappropriate the intellectual property rights or otherwise violate the rights of any third
        party. By submitting Content and Personal Information (as defined in the Privacy Policy) to TiCCBox, you hereby
        grant to TiCCBox a worldwide, non-exclusive, royalty-free, sublicensable and transferable license to use,
        reproduce and prepare derivative works of such Content and Personal Information for any lawful purpose and
        to display, distribute, give away or sell such Content and Personal Information to any party or parties not
        prohibited by law and subject to the constraints contained within the Privacy Policy in any media format and
        through any media channels.

        <br><br><span class="SubHead">3. Access, Passwords and Security </span><br><br>
        You may designate up to the number of users under your account which corresponds to the level of Service you
        are receiving from TiCCBox, and you may provide and assign access and passwords to such users. You will be
        responsible for the confidentiality and use of your access number(s), password(s), and account number(s).
        You will be responsible for all electronic communications, including account registration and other account
        holder information and other data including any Content (<strong>"Electronic Communications"</strong>)
        entered through or under your access number(s), password(s) or account number(s). TiCCBox will assume that any
        Electronic Communications it receives under your access number(s), password(s) or account numbers(s) will
        have been sent by you. You agree to immediately notify TiCCBox if you become aware of any loss or theft or
        unauthorized use of any of your access number(s), password(s) and/or account number(s).

        <br><br><span class="SubHead">4. Member Conduct</span><br><br>
        By submitting any Content to TiCCBox, you warrant that: (i) you are the owner of such Content or are authorized
        by the owner, and (ii) the use of such Content by TiCCBox will not infringe or misappropriate the intellectual
        property rights of or otherwise violate the rights of any third party. You agree to not use the Portal to:
        <br><br> > interfere with or disrupt the Portal or networks connected to the Portal.
        <br><br> > violate any applicable law or regulation.
        <br><br> > incite or provide instructional information about illegal activities.

        <br><br><span class="SubHead">5. General Practices Regarding Use, Storage and Service Access</span><br><br>
        You acknowledge that TiCCBox may establish from time to time general practices and limits concerning use of the
        Portal, including without limitation, establishing the maximum amount of storage space you have on the
        Portal at any time, as well as limiting the number of times (and the maximum duration for which) you may
        access the Portal in a given period of time. You agree that TiCCBox has no responsibility or liability for the
        deletion or failure to store any messages and other communications or other Content maintained or
        transmitted to or from the Portal. As a condition of use for this Portal, you agree that, in the event of an
        error with your Service, a TiCCBox technician shall be permitted to access your information as necessary to
        resolve the problem. You acknowledge that TiCCBox reserves the right to change these general practices and
        limits at any time, in its sole discretion, with or without notice.

        <br><br><span class="SubHead">6. Medical or Health Information</span><br><br>
        You acknowledge and agree that you are solely responsible for using the Portal and Content in a manner
        consistent with all applicable privacy laws relating to medical or health information.

        <br><br><span class="SubHead">7. Modifications to the Service and Terms of Use</span><br><br>
        7.1 Modifications to the Service. TiCCBox reserves the right at any time and from time to time to modify or
        discontinue, temporarily or permanently, the Portal (or any part thereof). You agree that TiCCBox shall not be
        liable to you or to any third party for any modification, suspension or discontinuance of the Portal or loss
        of access to the Content. TiCCBox may specify from time to time the version(s) of related products required in
        order to use the Portal (e.g. supported browser versions).
        <br><br>7.2 Modifications to the Terms of Use. You agree that TiCCBox may modify the Terms of Use if necessary
        to comply with any other agreements that TiCCBox is currently bound by or will be bound by in the future, and/or
        with applicable law, as well as to adjust to changing business circumstances. Your continued use of the
        Service shall constitute your acceptance of the Terms of Use with the new modifications. If you do not agree
        to any of such changes, you should immediately cease all access and use of the Portal. You agree that such
        termination of your use of the Portal will be your sole and exclusive remedy if you do not wish to abide by
        any changes to the Terms of Use.

        <br><br><span class="SubHead">8. Charges/Termination</span><br><br>
        You agree to pay the then-current fees associated with the Portal Services you choose. If you decide to
        terminate access to the portal, such termination shall be effective at the end of the last day of the
        calendar month that we receive your termination notice. If TiCCBox terminates your access to the Portal pursuant
        to these Terms of Use, such termination shall be effective immediately. The calendar month that the Portal
        is terminated for any reason is the <strong>"Termination Month"</strong>. During or after any Termination
        Month, in lieu of the normal billing procedure, you shall be responsible for all fees incurred by you up to
        the effective date of the termination. There shall be no refunds or credits to users of the Portal except as
        expressly provided in this Section unless TiCCBox elects to issue a refund or credit to users of the Portal on a
        case by case basis in its sole and absolute discretion.

        <br><br><span class="SubHead">9. Termination and Indemnity</span><br><br>
        You acknowledge and agree that TiCCBox may suspend or terminate your account and/or deny you access to, use of,
        or submission of Content for, all or part of the Portal, without prior notice, if you engage in any conduct
        that TiCCBox believes, in its sole discretion: (a) violates any term or provision of the Terms of Use, (b)
        violates the rights of TiCCBox or third parties, (c) or is otherwise inappropriate for continued access and use
        of the Portal. In addition, TiCCBox reserves the right to terminate inactive accounts. You agree that upon
        termination of the Service, either by you or TiCCBox, TiCCBox may delete all Content and information related to your
        account and may bar your access to the Portal including, but not limited to, access to any of your Content
        entered into or used in connection with the Portal. Further, you agree that TiCCBox shall not be liable to you
        or any third-party for any termination of your access to the Portal. You agree to defend, indemnify and hold
        TiCCBox harmless from and against any and all claims, losses, liability costs and expenses (including but not
        limited to legal fees and expenses) arising from: (i) any inaccuracy or falsity related to the Content or
        the Personal Information (as defined in the Privacy Policy) and, (ii) your violation of the Terms of Use,
        laws or regulations, or any third party's rights, including but not limited to infringement of any
        copyright, violation of any proprietary right and invasion of any privacy rights. This obligation shall
        survive the termination of Service.

        <br><br><span class="SubHead">10. Links</span><br><br>
        The Portal may provide links to other World Wide Web sites or resources. You acknowledge and agree that TiCCBox
        is not responsible for the availability of such external sites or resources, and does not endorse and is not
        responsible or liable for any content, advertising, products, or other materials on or available from such
        sites or resources. You further acknowledge and agree that TiCCBox shall not be responsible or liable, directly
        or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or
        reliance on any such content, goods or services available on or through any such site or resource.

        <br><br><span class="SubHead">11. TiCCBox and Third Party Proprietary Rights</span><br><br>
        You acknowledge and agree that the Portal contains proprietary and confidential information that is
        protected by applicable intellectual property and other laws. You further acknowledge and agree that content
        contained in sponsor advertisements or information that may have been presented to you through the Portal or
        advertisers is protected by copyrights, trademarks, service marks, patents or other proprietary rights and
        laws.

        <br><br><span class="SubHead">12. No Resale, Etc. of the Service </span><br><br>
        You agree not to copy, sell, resell, rent or sublicense (including offering the Service to third parties on
        an applications service provider or time-sharing basis), lease, loan, give, redistribute, or create a
        derivative work of any portion of the Service, use of the Service, or access to the Service. You agree not
        to access the Service by any means other than through the interface that is provided by TiCCBox for use in
        accessing the Service. You may not use, copy, modify or transfer the Service, in whole or in part, except as
        expressly provided in these Terms and Conditions. You agree to not make any attempt to decompile,
        disassemble or reverse engineer the Service or otherwise discover or derive the source code or underlying
        processes, ideas, structure, organization or algorithms of the Portal.

        <br><br><span class="SubHead">13. Disclaimer of Representations and Warranties </span><br><br>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
        <br><br>YOUR USE OF THE PORTAL IS AT YOUR SOLE RISK. THE PORTAL AND ANY CONTENT OR ADD-ON FEATURES THROUGH
        THE PORTAL ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TICCBOX AND ITS SUPPLIERS EXPRESSLY DISCLAIM ALL
        REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        <br><br>TICCBOX AND ITS SUPPLIERS MAKE NO REPRESENTATION OR WARRANTY THAT THE PORTAL AND ANY CONTENT OR ADD-ON
        FEATURES THROUGH THE PORTAL (i) WILL MEET YOUR REQUIREMENTS OR RESULT IN REVENUES OR PROFITS, (ii) WILL BE
        UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) WILL PROVIDE RESULTS THAT ARE ACCURATE OR RELIABLE, AND
        (iv) WILL MEET YOUR QUALITY EXPECTATIONS OF ANY PRODUCTS, SERVICE, INFORMATION, OR OTHER MATERIAL PURCHASED
        OR OBTAINED BY YOU THROUGH THE PORTAL. TICCBOX EXPRESSLY DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES THAT ANY
        LAWS OR REGULATIONS DESCRIBED IN THE PORTAL HAVE BEEN DESCRIBED ACCURATELY OR COMPLETELY AND THAT YOUR USE
        OF THE PORTAL WILL SATISFY ANY STATUTORY OR REGULATORY OBLIGATIONS, OR WILL ASSIST WITH, GUARANTEE OR
        OTHERWISE ENSURE COMPLIANCE WITH ANY APPLICABLE LAWS OR REGULATIONS. YOU ARE SOLELY RESPONSIBLE FOR ENSURING
        THAT YOUR USE OF THIS PORTAL OR CONTENT IS IN ACCORDANCE WITH APPLICABLE LAW.
        <br><br>ANY CONTENT OR MATERIAL TRANSFERRED FROM, DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
        PORTAL OR ADD-ON FEATURES THROUGH THE PORTAL ARE DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY
        RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA AND/OR CONTENT THAT RESULTS FROM THE
        TRANSFER OR DOWNLOAD OF ANY SUCH MATERIAL.
        <br><br>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM TICCBOX OR THROUGH OR FROM THE
        PORTAL OR ADD-ON FEATURES THROUGH THE PORTAL SHALL CREATE ANY REPRESENTATION OR WARRANTY NOT EXPRESSLY
        STATED IN THE TERMS OF USE.

        <br><br><span class="SubHead">14. Limitation of Liability</span><br><br>
        (a) YOU EXPRESSLY UNDERSTAND AND AGREE THAT TICCBOX AND ITS SUPPLIERS SHALL NOT BE LIABLE FOR ANY DIRECT,
        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
        LOSS OF PROFITS, GOODWILL, USE, CONTENT OR OTHER INTANGIBLE LOSSES (EVEN IF TICCBOX HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE PORTAL, CONTENT AND/OR
        ANY INFORMATION; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICE RESULTING FROM ANY GOODS,
        CONTENT, INFORMATION OR SERVICE PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO
        THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT;
        (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE PORTAL; OR (v) ANY OTHER MATTER RELATING TO THE
        SERVICE;
        <br><br>(b) YOU ALSO AGREE THAT TICCBOX WILL NOT BE LIABLE FOR ANY (i) INTERRUPTION OF BUSINESS, (ii) ACCESS
        DELAYS OR ACCESS INTERRUPTIONS TO THIS PORTAL OR THE WEB SITE(S) YOU ACCESS THROUGH THIS SERVICE; (iii)
        CONTENT NON-DELIVERY, MIS-DELIVERY, ERRORS, OMISSIONS, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION; OR
        (iv) EVENTS BEYOND TICCBOX'S REASONABLE CONTROL; AND
        <br><br>(c) IN NO EVENT SHALL TICCBOX'S MAXIMUM AGGREGATE LIABILITY EXCEED THE TOTAL AMOUNT PAID BY YOU TO TICCBOX
        FOR THE PORTAL USE IN THE PROCEEDING TWO (2) MONTHS. SOME DURISTRICTIONS MAY NOT ALLOW THE EXCLUSION OR
        LIMITATION OF CERTAIN WARRANTYS OR LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
        DURISTRICTIONS, TICCBOX’S LIABILITY IS LIMITED TO THE EXTENT PERMITTED BY LAW.

        <br><br><span class="SubHead">15. Non-Discrimination Policy </span><br><br>
        TiCCBox does not discriminate on the basis of race, color, sex, religion, national origin, handicap or familial
        status in the establishment of fees, entrance qualifications or standards for the use of the Portal.

        <br><br><span class="SubHead">16. Survival </span><br><br>
        All representations and warranties contained herein and all of the Provisions of the Privacy Policy shall
        survive after your use of the Service has terminated for any reason.

        <br><br><br><span class="SubHead">ACCEPTABLE USE POLICY</span><br><br>

        <br><br><span class="SubHead">1. Responsibility for Content</span><br><br>
        You understand that all Content, whether publicly posted or privately transmitted, is the sole
        responsibility of the person from which such Content originated. This means that you and other third
        parties, and not TiCCBox, are entirely responsible for all Content that you upload or otherwise view or transmit
        via the Portal. TiCCBox does not control the Content uploaded or otherwise transmitted by you or other third
        parties via the Portal and, as such, does not guarantee the accuracy, integrity or quality of such Content.

        <br><br><span class="SubHead">2. Other Conduct</span><br><br>
        You agree to not use the Portal to: (a) upload or otherwise transmit any Content or domain name that is
        unlawful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of
        another's privacy, hateful, or racially, ethnically or otherwise objectionable; (b) harm or exploit minors
        in any way; (c) impersonate any person or entity, including, but not limited to, any TiCCBox representative, or
        misrepresent your affiliation with any person or entity; (d) forge headers or otherwise manipulate
        identifiers in order to disguise the origin of any Content transmitted through the Portal; (e) upload or
        otherwise transmit any Content that you do not have a right to transmit under any law or under contractual
        relationships (including but not limited to inside information, proprietary and confidential information
        learned or disclosed as part of employment relationships or under nondisclosure agreements); (f) upload or
        otherwise transmit any Content or domain name that infringes any patent, trademark, trade secret, copyright
        or other proprietary rights of any person; (g) upload or otherwise transmit any unsolicited or unauthorized
        advertising, promotional materials, "junk mail", "spam", "chain letters", "pyramid schemes", or any other
        form of solicitation, except in those areas of the portal that may be designated for such purpose; (h)
        upload or otherwise transmit any material that contains software viruses or any other computer code, files
        or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware
        or telecommunications equipment; (i) interfere with or disrupt the Portal or servers or networks connected
        to the Portals; (j) violate any applicable law or regulation. (k) incite or provide instructional
        information about illegal activities; or (l) conduct raffles, contests, lotteries or sweepstakes, except in
        those areas of the Portal that may be designated for such purpose.

        <br><br><span class="SubHead">3. Other</span><br><br>
        This Acceptable Use Policy is subject to change at any time without notice.

        <br><br><br><span class="SubHead">PRIVACY POLICY</span><br><br><br>

        Supplier is committed to protecting your privacy. This "Privacy Policy" explains data collection and use
        practices of the supplier portal. By accessing the portal or using the Service, you are consenting to the
        information collection and use practices described in this Privacy Policy.

        <br><br><span class="SubHead">Collection of your Personal Information</span><br><br>
        Supplier will ask you when information is needed that personally identifies you or allows supplier to
        contact you to provide a service or carry out a transaction that you have requested such as receiving
        information about supplier services, joining a limited-access supplier portal or service, or signing up for
        an event. The personal information supplier collects may include, but not be limited to, your name, title,
        company or organization name, work or personal e-mail, work or personal phone, work or home address,
        information about your job function, information about your company, and credit or debit card information
        (collectively, "Personal Information").
        <br><br>
        The portal may collect certain information about your visit, such as the name of the Internet service
        provider and the Internet Protocol (IP) address through which you access the Internet; the date and time you
        access the portal; the pages that you access while at the portal and the Internet address of the Web site
        from which you linked directly to supplier’s portal. The foregoing is also considered Personal Information.
        The Personal Information is used to help improve the portal, analyse trends, administer the portal and for
        any other lawful purpose. Some of Supplier’s sites may collect information about your hardware and software
        in order to provide a requested service.

        <br><br><span class="SubHead">Use of your Personal Information and Content/data</span><br><br>
        The Personal Information and Content/data collected on the portal will be used to operate the portal and to
        provide the Service or carry out the transaction(s) you have requested or authorized and for any other
        lawful purpose. The Personal Information and Content is sometimes collectively referred to herein as
        "Information." Supplier may use Information to provide you with more effective customer service, to improve
        the portal and any related Supplier services, and to make the portal easier to use by eliminating the need
        for you to repeatedly enter the same information or by customizing the portal to your particular preference
        or interests. In order to offer you a more consistent experience in your interactions with Supplier,
        information collected by Supplier may be combined with information collected by other Supplier services.
        <br><br>
        Supplier may use Information to provide you with information about the Service that you are using, including
        critical updates and notifications. Additionally, anything in the Agreement of Sale and Privacy Policy to
        the contrary notwithstanding, except as otherwise prohibited by law, Supplier may send you information about
        other Supplier services, and/or share, distribute, give or sell the Information (not including any personal
        financial information such as credit or debit card information) to anyone it desires, including but not
        limited to the general public and to Supplier partners, subsidiaries, parents, affiliates, joint venturers,
        customers, vendors, successors, assigns and other interested parties.
        <br><br>
        Supplier may disclose Personal Information if required to do so by law or in the good faith belief that such
        action is necessary to (a) conform to the requirements of the law or comply with legal process served on
        Supplier or the portal (b) protect and defend the rights or property of Supplier and Supplier’s family of
        Web sites; or (c) act in urgent circumstances to protect the personal safety of Supplier employees or
        agents, users of Supplier products or services, or members of the public.


        <br><br><span class="SubHead">Control of your Personal Information</span><br><br>
        Except as otherwise described in this Privacy Policy, Personal Information you provide on the portal will
        not be shared outside of Supplier without your permission. As described below, you can access your Personal
        Information and make choices about how you wish to be contacted. Please be aware that this Privacy Policy
        and any choices you make on the portal will not necessarily apply to Personal Information you may have
        provided to Supplier in the context of other, separately operated, Supplier services.
        <br><br>
        Supplier may send out periodic e-mails informing you of technical service or security issues related to a
        service you requested, or confirming you requested a service. In some services offered by Supplier, contact
        with the customer is an intrinsic part of the service. You will not be able to choose to unsubscribe to
        these mailings, as they are considered an essential part of the service(s) you have chosen.

        <br><br><span class="SubHead">Security of your Personal Information</span><br><br>
        Supplier is committed to protecting the security of your Personal Information. Supplier uses a variety of
        security technologies and procedures to help protect your Personal Information from unauthorized access,
        use, or disclosure. For example, Supplier stores the Personal Information you provide on computer servers
        with limited access that are located in controlled facilities. Additionally, when Supplier transmits
        sensitive Personal Information (such as a credit card number) over the Internet, Supplier protects it
        through the use of encryption, such as the Secure Socket Layer (SSL) protocol.

        <br><br><span class="SubHead">Use of Cookies</span><br><br>
        Supplier uses cookies on the portal to ensure the integrity of the registration process and to personalize
        the portal. A cookie is a small text file that is placed on your hard disk by a Web page server. Cookies
        cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you,
        and can only be read by a Web server in the domain that issued the cookie to you. One of the primary
        purposes of cookies is to provide a convenience feature to save you time. For example, if you personalize a
        web page, or navigate within a site, a cookie helps the site to recall your specific information on
        subsequent visits. This simplifies the process of delivering relevant content, eases site navigation, and so
        on. When you return to the Web site, the information you previously provided can be retrieved, so you can
        easily use the portal's features that you customized.
        <br><br>
        You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you
        can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies,
        you may not be able to fully experience the interactive features of the portal or other Web sites you visit.

        <br><br><span class="SubHead">Changes to this Policy</span><br><br>
        Supplier may occasionally update this Privacy Policy. Supplier encourages you to periodically review this
        Privacy Policy to stay informed about how Supplier is helping to protect the Personal Information collected.
        Your continued use of the Service constitutes your agreement to this Privacy Policy and any updates thereto.

        <br><br><span class="SubHead">
          I ACKNOWLEDGE THAT I HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND AGREE TO BE
          BOUND BY ITS TERMS AND CONDITIONS.
        </span><br><br>

      </div>

      <div class="form-check mb-3">
        <input #acceptInput class="form-check-input" type="checkbox" id="accept" [formControl]="acceptFC">
        <label class="form-check-label" for="accept">
          I have read the above Terms of Use and Privacy Policy
        </label>
      </div>

      <button type="button" class="btn btn-primary" (click)="onFormSubmit()" [disabled]="!acceptInput.checked">
        Continue
      </button>


    </div>
    <div class="d-flex justify-content-around">
      <div> Email: <a href="mailto:info@ticcbox.com">info@ticcbox.com</a>
      </div>
      <div>
        <a href="assets/documents/DCS_Energy_Savings_Ltd_End_User_License_Agreement.pdf" target="_blank"> Terms of
          Use and Privacy Policy </a>
      </div>
      <div> © 2008-{{currentYear}} by <a href="https://ticcbox.com//" target="_blank">ticcbox.com/</a>
      </div>
    </div>
  </div>
</div>

