import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@api/auth.service';
import {take, tap} from 'rxjs/operators';
import {ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";

@Component({
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService]
})
export class RequestResetPasswordComponent {

  resetPasswordFG: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
  });

  isResetPasswordButtonLocked$ = new Subject<boolean>();

  get currentYear(): number {
    return new Date().getFullYear();
  }

  constructor(private authService: AuthService, private route: ActivatedRoute) {

    if (this.route.snapshot.queryParams.email) {
      this.resetPasswordFG.patchValue({
        email: route.snapshot.queryParams.email
      });
    }
  }

  onResetPasswordSubmit() {
    if (this.resetPasswordFG.valid) {
      this.isResetPasswordButtonLocked$.next(true);
      this.authService.requestResetPassword(this.resetPasswordFG.value).pipe(take(1),
        tap(() => {
          this.isResetPasswordButtonLocked$.next(false);
        })) .subscribe(() => { }, error => {
          this.isResetPasswordButtonLocked$.next(false);
        });
    }
  }
}
