<mat-toolbar class="navbar-container">
  <ng-container *ngIf="logos$ | async as logos">
    <img class="logo" *ngFor="let portalLogo of logos.portalLogos" [src]="'data:image/jpg;base64,' + portalLogo"
      alt="portal logo" />
    <img class="logo" *ngFor="let siteLogos of logos.siteLogos" [src]="'data:image/jpg;base64,' + siteLogos"
      alt="site logo" />
  </ng-container>
  <span class="spacer"></span>

  <!-- Portals -->
  <button mat-button [matMenuTriggerFor]="portalMenu">
    <ng-container *ngIf="selectedPortal$ | async as selectedPortal">
      {{ selectedPortal?.name }}
    </ng-container>
  </button>
  <mat-menu #portalMenu="matMenu">
    <button mat-menu-item *ngFor="let item of portals$ | async as list" (click)="onSelectedPortalChange(item)">
      {{ item.name }}
    </button>
  </mat-menu>

  <!-- Sites -->
  <button mat-button [matMenuTriggerFor]="siteMenu">
    <ng-container *ngIf="selectedSite$ | async as selectedSite">
      {{ selectedSite?.name }}
    </ng-container>
  </button>
  <mat-menu #siteMenu="matMenu">
    <button mat-menu-item *ngFor="let item of sites$ | async as list" (click)="onSelectedSiteChange(item)">
      {{ item.name }}
    </button>
  </mat-menu>

  <!--User Manual-->
  <button mat-button [matMenuTriggerFor]="userMenu">User Menu</button>
  <mat-menu #userMenu="matMenu">
    <a mat-menu-item  [href]="userManuelUrl$ | async" target="_blank">
      <svg-icon class="d-inline-block me-2" src="assets/images/svg/nav-bar/User Mannual.svg"
        [svgStyle]="{ 'width.px':20,'height.px': 20,'fill':'#fff'}">
      </svg-icon>
      <span>User Manual</span>
    </a>
    <button mat-menu-item (click)="openDashboardReport()">
      <svg-icon class="d-inline-block me-2" src="assets/images/svg/nav-bar/Dashboard Report.svg"
        [svgStyle]="{ 'width.px':20,'height.px': 20,'fill':'#fff'}">
      </svg-icon>
      <span>Dashboard Report</span>
    </button>
    <button mat-menu-item (click)="openAggregatedReport()">
      <svg-icon class="d-inline-block me-2" src="assets/images/svg/nav-bar/Aggregate.svg"
        [svgStyle]="{ 'width.px':20,'height.px': 20,'fill':'#fff'}">
      </svg-icon>
      <span>Aggregated Report</span>
    </button>
    <button  mat-menu-item (click)="openMap()">
      <svg-icon class="d-inline-block me-2" src="assets/images/svg/nav-bar/Map.svg"
        [svgStyle]="{ 'width.px':20,'height.px': 20,'fill':'#fff'}">
      </svg-icon>
      <span>Map</span>
    </button>
  </mat-menu>

  <button mat-button color="warn" (click)="onLogout()">Logout</button>
</mat-toolbar>