import { Injectable } from '@angular/core';
import { IExtractedUserFromJWT, IUser, User } from '@models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserStateService {
    currentUserSource = new BehaviorSubject<IExtractedUserFromJWT | null>(null);
    currentUser$: Observable<IUser | null> = this.currentUserSource.pipe(
        map((jwtUser: IExtractedUserFromJWT | null) => {
            if (jwtUser !== null) {
                return new User(jwtUser);
            }

            return null;
        }
        ));

    isLoggedIn$ = this.currentUserSource.pipe(map(Boolean));

    constructor() {
    }
}
