import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {AuthService} from "@api/auth.service";
import {AcceptTermsRequestModel} from "@models/backend-generated-models/models";
import {take} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService]
})
export class AcceptTermsComponent {
  acceptFC = new FormControl('', [Validators.requiredTrue]);

  get currentYear(): number {
    return new Date().getFullYear();
  }

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  onFormSubmit() {
    if (this.acceptFC.valid) {
      const token = this.route.snapshot.queryParams.token;

      if (!token) return;

      const body = {
        accepted: this.acceptFC.value,
        token,
      } as AcceptTermsRequestModel;

      this.authService.acceptTerms(body, token).pipe(take(1)).subscribe();
    }
  }
}
