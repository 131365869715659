import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '@environment/environment';
import {catchError} from 'rxjs/operators';
import {ErrorHandlerService} from '@helpers/error-handler.service';
import {of, throwError} from 'rxjs';
import {LogoVM, SiteVM, PortalVM} from "@models/backend-generated-models/models";

@Injectable({providedIn: 'root'})
export class PortalService {
  constructor(private httpClient: HttpClient, private errorHandler: ErrorHandlerService) {
  }

  getPortals$() {
    return this.httpClient.get<PortalVM[]>(`${environment.apiUrl}portal`).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return of([] as PortalVM[]);
        }
      )
    );
  }

  getSitesForPortal$(portalId: number | undefined) {

    if (!portalId) return throwError('PortalId is required');

    return this.httpClient.get<SiteVM[]>(`${environment.apiUrl}portal/${portalId}/sites`).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return of([] as SiteVM[]);
        }
      )
    );
  }

  getLogos$(siteId: number | undefined) {

    if (!siteId) return throwError('SiteId is required');

    const params = new HttpParams().set("siteId", siteId);

    return this.httpClient.get<LogoVM>(`${environment.apiUrl}portal/logo`, {
      params
    }).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);

          return of({} as LogoVM);
        }
      )
    );
  }
}
