import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {LineChartViewMode} from '@models/backend-generated-models/models';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-charts-line-chart',
  templateUrl: './charts-line-chart.component.html',
  styleUrls: ['./charts-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartsLineChartComponent {

  @Input() chart?: Observable<LineChartViewMode[] | undefined> | null | undefined;

  view: [number, number] = [500, 200];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  timeline: boolean = false;
  autoScale: boolean = true;
}
