import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { ErrorHandlerService } from "@helpers/error-handler.service";
import { LineChartResponse } from "@models/backend-generated-models/models";
import { throwError } from 'rxjs';
import { catchError } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class UserMenuService {
    constructor(private httpClient: HttpClient, private errorHandler: ErrorHandlerService) {
    }

    getUserManualURL$() {
        return this.httpClient.get(`${environment.apiUrl}usermenu`, {
            responseType: 'text'
        }).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    this.errorHandler.handleError(error);

                    return throwError(error);
                }
            )
        );
    }

    getAggregatedReportURL$() {
        return this.httpClient.get(`${environment.apiUrl}usermenu/aggregated-report`,
            { responseType: 'text' })
            .pipe(
                catchError(
                    (error: HttpErrorResponse) => {
                        this.errorHandler.handleError(error);

                        return throwError(error);
                    }
                )
            );
    }

    getReportViewerURL$() {
        return this.httpClient.get(`${environment.apiUrl}usermenu/report-viewer`,
            { responseType: 'text' })
            .pipe(
                catchError(
                    (error: HttpErrorResponse) => {
                        this.errorHandler.handleError(error);

                        return throwError(error);
                    }
                )
            );
    }

    getRptResourceConsumption$(siteId: number, logicalGroupId: number, period: string, dateLeft: string, dateRight: string) {
        if (!siteId) return throwError('SiteId is required');
        if (!logicalGroupId) return throwError('Select Chart');
        const params = new HttpParams()
            .set("siteId", siteId)
            .set("logicalGroupId", logicalGroupId)
            .set("period", period)
            .set("dateLeft", dateLeft)
            .set("dateRight", dateRight);

        return this.httpClient.get<LineChartResponse>(`${environment.apiUrl}usermenu/dashboard-report`, {
            params
        }).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    this.errorHandler.handleError(error);

                    return throwError(error);
                }
            )
        );
    }
}
