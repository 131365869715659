<div class="container">
  <div class="content">
    <div class="header">
      <h2 class="text-info">TiCCBox Carbon PORTAL</h2>
    </div>
    <div class="body">
      <div id="reset-password">
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-8">
              <div class="col-md-12">
                <h3 class="text-info text-center">Reset password</h3>
                <div class="spinner">
                  <mat-spinner class="spinner-body"
                    [ngStyle]="{ display: !(isResetPasswordButtonLocked$ | async) ? 'none' : 'block' }"></mat-spinner>
                </div>
                <form [formGroup]="resetPasswordFG" [ngClass]="{ blur: (isResetPasswordButtonLocked$ | async) }"
                  (ngSubmit)="onResetPasswordSubmit()">
                  <div class="mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input type="email" [attr.disabled]="(isResetPasswordButtonLocked$ | async)? true : null" class="form-control" id="email"
                      formControlName="email">
                  </div>
                  <div class="d-flex justify-content-between">
                    <button type="submit" class="btn btn-info btn-md text-white"
                      [disabled]="isResetPasswordButtonLocked$ | async">Request a reset link
                    </button>
                    <a mat-button routerLink="../login">Go to login page</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around">
      <div> Email: <a href="mailto:info@ticcbox.com">info@ticcbox.com</a>
      </div>
      <div>
        <a href="assets/documents/DCS_Energy_Savings_Ltd_End_User_License_Agreement.pdf" target="_blank"> Terms of
          Use and Privacy Policy </a>
      </div>
      <div> © 2008-{{currentYear}} by <a href="https://ticcbox.com" target="_blank">ticcbox.com</a>
      </div>
    </div>
  </div>
</div>