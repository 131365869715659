import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@guards/auth.guard';
import {HomeComponent} from '@views/home/home.component';
import {LoginComponent} from '@views/login/login.component';
import {RequestResetPasswordComponent} from '@views/request-reset-password/request-reset-password.component';
import {ConfirmResetPasswordComponent} from "@views/confirm-reset-password/confirm-reset-password.component";
import {PageNotFoundComponent} from "@views/page-not-found/page-not-found.component";
import {AcceptTermsComponent} from "@views/accept-terms/accept-terms.component";
import {HiddenTestNotificationComponent} from "@views/hidden-test-notification/hidden-test-notification.component";

const routes: Routes = [

  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'request-reset-password',
    component: RequestResetPasswordComponent,
  },
  {
    path: 'confirm-reset-password',
    component: ConfirmResetPasswordComponent,
  },
  {
    path: 'accept-terms',
    component: AcceptTermsComponent,
  },
  {
    path: 'random-unique-non-easy-to-guess-url',
    component: HiddenTestNotificationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
