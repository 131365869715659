import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FPCategoryVM, GroupVM } from "@models/backend-generated-models/models";
import * as moment from 'moment';
import { HttpParams } from "@angular/common/http";
import { Observable, Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

//
@Component({
  selector: 'app-modal-report-base',
  templateUrl: './modal-reports.component.html',
  styleUrls: ['./modal-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalReportsComponent implements OnInit {
  iframeUrl: string = "";
  params = new HttpParams();
  url: string = "";
  aPeriodList: string[] = [];
  selectedPeriod: string | undefined = "";

  chartTypeList: IChart[] = [];
  chartType: IChart = {} as IChart;

  startDate: Date = new Date();
  startDateForIframe: Date = new Date();

  endDate: Date = new Date();
  endDateForIframe: Date = new Date();

  isUrlSafeLoad: boolean = true;
  isShowDatepicker: boolean = false;
  isShowCompare: boolean = false;
  isCompare: boolean = false;
  isToolbarActive: boolean = false;
  isShowLabel: boolean = true;
  isShowGroups: boolean = false;

  selectedGroup: GroupVM = {} as GroupVM;

  periodListType = { "D": "Day", "W": "Week", "M": "Month", "Y": "Year" };

  isLoading$ = new Subject<boolean>();
  isResourceRemainderConsumption$ = new Subject<boolean>();
  isUrlsReturnLength = new Subject<boolean>();
  current_url: SafeUrl | undefined;
  constructor(private dialogRef: MatDialogRef<ModalReportsComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { category: FPCategoryVM, url: Observable<string> }
  ) {
  }

  ngOnInit(): void {
    this.isLoading$.next(true);
    this.isResourceRemainderConsumption$.next(false);
    this.data.url.pipe().subscribe(url => {
      this.url = url;
      this.isLoading$.next(false);
      this.loadingReport();
    });
  }

  loadingReport() {
    this.params = this.params.set('aPortalID', localStorage.getItem('portalId')!);
    this.params = this.params.set('aSiteID', localStorage.getItem('siteId')!);

    if (this.data.category.groups) {
      this.selectedGroup = this.data.category.groups[0];
    }

    this.createPeriodList();
    this.createChartTypeList();
    this.createDefaultData();
    this.createCompare();
    this.createHttpParams();

    this.isUrlSafeLoad = false;
  }

  changeChartType(chartType: IChart) {
    this.chartType = chartType;
    this.params = this.params.set('aChartType', this.chartType.name);
    this.getUrl();
  }

  changeGroup(group: GroupVM) {
    this.selectedGroup = group;

    this.params = this.params.set(this.selectedGroup.type!, this.selectedGroup.id!);
    this.params = this.params.set('aReadingTypeID', this.selectedGroup.readingTypeId!);

    this.getUrl();
  }

  changeShowLabel() {
    this.params = this.params.set('aShowLabel', this.isShowLabel);
    this.getUrl();
  }

  changeToolbar() {
    this.params = this.params.set('rc:Toolbar', this.isToolbarActive);
    this.getUrl();
  }

  changeStartDate() {
    this.startDateForIframe = this.changeStartDateWithPeriod(this.startDate);
    this.params = this.params.set('aDateLeft', this.dateToString(this.startDateForIframe));
    this.getUrl();
  }

  changeEndDate() {
    if (this.isCompare)
      this.endDateForIframe = this.changeStartDateWithPeriod(this.endDate);
    else
      this.endDateForIframe = this.changeEndDateWithPeriod(this.endDate);

    this.params = this.params.set('aDateRight', this.dateToString(this.endDateForIframe));
    this.getUrl();
  }

  changeCompare() {
    this.changePeriod(this.selectedPeriod!);
  }

  changePeriod(period: string) {

    this.selectedPeriod = period;

    this.changeStartDate();
    this.changeEndDate();

    if (this.selectedPeriod != null) {
      this.params = this.params.set('aPeriod', (this.isCompare ? "1" : "") + this.selectedPeriod[0].toLowerCase());
    }

    this.getUrl();
  }

  private changeStartDateWithPeriod(date: Date): Date {
    let startDate = new Date(date);
    startDate.setHours(0, 0, 0);

    if (this.selectedPeriod == "Week")
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1);

    else if (this.selectedPeriod == "Month")
      startDate.setDate(1);

    else if (this.selectedPeriod == "Year") {
      startDate.setMonth(0);
      startDate.setDate(1);
    }

    return startDate;
  }

  private changeEndDateWithPeriod(date: Date): Date {
    let endDate = new Date(date);
    endDate.setHours(23, 59, 59);

    if (this.selectedPeriod == "Week")
      endDate.setDate(endDate.getDate() - endDate.getDay() + 7);

    else if (this.selectedPeriod == "Month") {
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
    } else if (this.selectedPeriod == "Year") {
      endDate.setMonth(12);
      endDate.setDate(0);
    }
    return endDate;
  }

  private createPeriodList() {
    this.aPeriodList = [];
    this.selectedPeriod = undefined;

    for (const [key, value] of Object.entries(this.periodListType)) {
      //get all possible values
      if (this.data.category.showFormat?.includes(key))
        this.aPeriodList.push(value);

      //get default value
      if (this.data.category.defaultFormat?.includes(key))
        this.selectedPeriod = value;
    }

    this.isShowDatepicker = this.aPeriodList.length != 0;

    if (this.isShowDatepicker) {
      if (this.selectedPeriod != null) {
        this.params = this.params.set('aPeriod', this.selectedPeriod[0].toLowerCase());
      }
    }
  }

  private createDefaultData() {
    this.endDate = new Date();

    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0);

    if (this.selectedPeriod == undefined)
      return;

    if (this.selectedPeriod == "Day") {
      const dayCount = +this.data.category.defaultFormat!.split('D')[0];
      this.startDate.setDate(this.startDate.getDate() - dayCount);
    } else if (this.selectedPeriod == "Week") {
      const weekCount = +this.data.category.defaultFormat!.split('W')[0];
      this.startDate.setDate(this.startDate.getDate() - weekCount * 7);
    } else if (this.selectedPeriod == "Month") {
      const monthCount = +this.data.category.defaultFormat!.split('M')[0];
      this.startDate.setMonth(this.startDate.getMonth() - monthCount);
    } else if (this.selectedPeriod == "Year") {
      const yearCount = +this.data.category.defaultFormat!.split('Y')[0];
      this.startDate.setFullYear(this.startDate.getFullYear() - yearCount);
    }
    this.changeStartDate();
    this.changeEndDate();
  }

  private createChartTypeList() {
    this.chartTypeList = [];

    if (this.data.category.showFormat?.includes("B"))
      this.chartTypeList.push({
        name: "Bar",
        iconName: 'bar_chart',
        tooltip: "Show Bar Chart Report"
      });

    if (this.data.category.showFormat?.includes("P"))
      this.chartTypeList.push({
        name: "Pie",
        iconName: 'pie_chart',
        tooltip: "Show Breakdown Pie Chart"
      })

    if (this.chartTypeList.length != 0) {
      if (this.data.category.defaultFormat?.includes('B')) {
        this.chartType = this.chartTypeList.filter(c => c.name == 'Bar')[0];
      } else if (this.data.category.defaultFormat?.includes('P')) {
        this.chartType = this.chartTypeList.filter(c => c.name == 'Pie')[0];
      }
      this.params = this.params.set('aChartType', this.chartType.name);
    }
  }

  private createCompare() {
    this.isShowCompare = this.data.category.showFormat?.includes("C") ?? false;

    this.isCompare = !!this.data.category.defaultFormat?.includes('C');
  }

  private createHttpParams() {
    if (this.selectedGroup) {
      this.params = this.params.set(this.selectedGroup.type!, this.selectedGroup.id!);

      if (this.selectedGroup.readingTypeId)
        this.params = this.params.set('aReadingTypeID', this.selectedGroup.readingTypeId);
    } else {
      this.params = this.params.set('aReadingTypeID:isNull', 'True');
    }

    this.params = this.params.set('rs:Command', 'Render');
    this.params = this.params.set('rc:Parameters', false);

    this.params = this.params.set('aShowLabel', this.isShowLabel);
    this.params = this.params.set('rc:Toolbar', this.isToolbarActive);
    this.getUrl();
  }

  getUrl() {
    if (this.data.category == undefined)
      return;
    this.iframeUrl = this.url;
    if (this.isCompare) {
      if (this.data.category.report?.reportUrl == "rptResourceRemainderConsumption") {
        this.iframeUrl += "Reports/rptResourceRemainderComparison&";
      } else {
        this.iframeUrl += "Reports/rptResourceCompare&";
      }
    } else {
      this.iframeUrl += "Reports/" + this.data.category.report?.reportUrl + "&";
    }

    this.iframeUrl += this.params.toString();

    this.current_url=this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl)

    if (this.data.category.report?.reportUrl == "rptResourceRemainderConsumption"
    || this.data.category.report?.reportUrl == "rptResourceConsumption"
    || this.data.category.report?.reportUrl == "rptResourceStatus" ) {
      this.isResourceRemainderConsumption$.next(true);
      
      window.addEventListener("message", (event) => {
        if (event.data.event = "InvokeReportActionEvent") {
          if (event.data.message != undefined) {
                this.isUrlsReturnLength.next(true);
            }
          }
        })
    }
  }
  
  returnLastUrl() {
    this.current_url=this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
    this.isUrlsReturnLength.next(false);
  }

  dateToString(date: Date): string {
    if (date == undefined)
      return "YYYY-MM-DD HH:mm:ss";

    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }

  getImageUrl(imageName: string) {
    // replace .png with .svg
    const svgImageName = imageName.replace(".png", ".svg");
    return `assets/images/svg/Items/${svgImageName}`;
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

interface IChart {
  name: string;
  iconName: string;
  tooltip: string;
}
