import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SiteVM} from "@models/backend-generated-models/models";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SiteStateService {

  selectedSiteSource = new BehaviorSubject<SiteVM | null>(null);
  selectedSite$ = this.selectedSiteSource.pipe(
    tap(
      (site) => {
        if (site) {
          localStorage.setItem('siteId', site.siteId!.toString());
        }
      }
    )
  )

  constructor() {
  }
}
